import {
  filterNullValues,
  getAllRoles,
  getPaywalledImgData,
  isPaywallProtected,
} from "@utils/paywallUtilities"
import { getBlockData, getImageMeta, getTaxonomy } from "."
import getNestedObject from "@utils/nestedObjects"
import NUMBERS from "@helpers/constants/numbers"
import { consoleError } from "@utils/error"
import getArticleTeaserCompositionData from "./getArticleTeaserCompositionData"
import getRotatingHeroBannerData from "./getRotatingHeroBannerData"
import getLinkIconData from "./getLinkIconData"
import { getApi } from "@utils/baseApi"
import getProductTeaserCardData from "./getProductTeaserCardData"

enum CAROUSEL_BLOCK_TYPE {
  HERO_BANNER = "hero_banner",
  ARTICLE_TEASER_COMPOSITION_BANNER = "article_teaser_composition",
  MENU_LINK_WITH_ICON = "menu_link_with_icon",
  PRODUCT_TEASER_CARDS = "pim_product_teaser",
  SANDOZ_ACADEMY_COURSE_CATALOGUE = "sandoz_academy_course_catalogue",
}

const getFlexibleCarouselData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getFlexibleCarouselData.name}.`)
  }
  let imageCarouselData = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(imageCarouselData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(imageCarouselData)
  const duration = Number(
    getNestedObject(imageCarouselData, "data.attributes.field_slide_duration") ?? NUMBERS.ZERO,
  )

  const itemPerSlide = Number(
    getNestedObject(imageCarouselData, "data.attributes.field_number_of_items_per_slide_") ??
      NUMBERS.ONE,
  )

  const carouselDisplayType = imageCarouselData?.data?.attributes?.field_display_type
  const carouselBlockType = imageCarouselData?.data?.attributes?.field_block_type

  const rotatingHeroBanerData = async (slide: any) => {
    try {
      const slideData = slide
      let rotatingHeroBannerData = slide
        ? await getRotatingHeroBannerData("url", 0, 0, slideData)
        : null
      let result
      if (rotatingHeroBannerData) {
        result = rotatingHeroBannerData?.slidesInfo
      }
      return result
    } catch (error) {
      console.error("Error in articlecomData:", error)
      throw error
    }
  }

  const getFlexibleCardsDataForCarousel = async (url: string) => {
    let flexibleCards = await getApi(url, false, "")
    let categoryData = await getApi("/jsonapi/solar/sandoz-academy-course-apiresponse", false, "")
    categoryData = categoryData?.data
    flexibleCards = flexibleCards?.data
    flexibleCards =
      flexibleCards && flexibleCards.length
        ? await Promise.all(
            flexibleCards?.map(async (flexCard: any) => {
              const isChildPaywalled = isPaywallProtected(
                getNestedObject(flexCard, "attributes.enable_paywall_block_level"),
              )?.isPaywallProtectedFlag

              if (isChildPaywalled) {
                return null
              }
              const imagePaywallData = isPaywallProtected(flexCard?.relationships?.field_sac_image)
              const { imageUrl, alt, imageStyledUrl } = imagePaywallData.isPaywallProtectedFlag
                ? getPaywalledImgData(imagePaywallData?.errorObj)
                : getImageMeta(flexCard?.relationships?.field_sac_image)
              const cardImage = {
                src: imageUrl,
                styledimage: imageStyledUrl,
                alt: alt,
              }
              const courseSuggestions = {
                label:
                  flexCard?.relationships?.field_course_suggestion?.data?.meta?.term_name || null,
                icon:
                  flexCard?.relationships?.field_course_suggestion?.data?.icon_type ||
                  "academyBarcodeIcon",
              }
              const courseCategorys = {
                label:
                  flexCard?.relationships?.field_course_category?.data?.meta?.term_name || null,
                icon: flexCard?.relationships?.field_course_category?.data?.icon_type || null,
              }
              const taxonomies = await getTaxonomy(
                flexCard?.relationships?.field_category?.links?.related?.href,
                true,
              )
              const cardType = await getTaxonomy(
                flexCard?.relationships?.field_event_type?.links?.related?.href,
                true,
              )
              const credits = [
                {
                  taxonomyLabel:
                    (flexCard?.attributes?.field_credits || "") +
                    " " +
                    (categoryData?.credits_label || ""),
                },
              ]

              const specialityId =
                flexCard?.relationships?.field_specialty?.data?.[0]?.meta
                  ?.drupal_internal__target_id ?? null
              const fromDate = flexCard?.attributes?.field_course_commencement_date || null
              const toDate = flexCard?.attributes?.field_course_expiration_date || null
              const difficultyLevel = {
                label:
                  flexCard?.relationships?.field_course_difficulty_level?.data?.meta?.term_name ||
                  null,
                icon:
                  flexCard?.relationships?.field_course_difficulty_level?.data?.icon_type || null,
              }
              const courseDuration = {
                label: flexCard?.attributes?.field_course_duration?.duration || null,
                icon: "TimeIcon",
              }
              return {
                id: flexCard?.attributes?.drupal_internal__nid ?? "",
                taxonomies: await getTaxonomy(
                  flexCard?.relationships?.field_sandoz_academy_topics?.links?.related?.href,
                  true,
                ),
                title: isPaywallProtected(flexCard?.attributes?.title).isPaywallProtectedFlag
                  ? null
                  : flexCard?.attributes?.title ?? null,
                desc: flexCard?.attributes?.body?.processed
                  ? flexCard?.attributes?.body?.processed
                  : null,
                time: isPaywallProtected(flexCard?.attributes?.field_time).isPaywallProtectedFlag
                  ? null
                  : flexCard?.attributes?.field_time ?? null,
                contentType: cardType?.[0]?.taxonomyLabel ?? null,
                avgRatingTitle: flexCard?.attributes?.field_avgRating?.title
                  ? flexCard?.attributes?.field_avgRating?.title
                  : null,
                difficultyLevel: difficultyLevel ? difficultyLevel : null,
                courseDuration: courseDuration ? courseDuration : null,
                fromLabel: categoryData?.sandoz_academy_from_date_label
                  ? categoryData?.sandoz_academy_from_date_label
                  : null,
                toLabel: categoryData?.sandoz_academy_to_date_label
                  ? categoryData?.sandoz_academy_to_date_label
                  : null,
                credits: credits ?? null,
                enableCredits: flexCard?.attributes?.field_enable_disable_credits,
                fromDate: isPaywallProtected(flexCard?.attributes?.field_course_commencement_date)
                  .isPaywallProtectedFlag
                  ? null
                  : fromDate,
                toDate: isPaywallProtected(flexCard?.attributes?.field_course_expiration_date)
                  .isPaywallProtectedFlag
                  ? null
                  : toDate,
                cardImage: cardImage,
                isRatingsAvailable:
                  categoryData?.disable_star_rating_on_catalouge_page === 0 ?? false,
                avgRating: flexCard?.attributes?.avgRating ?? 4,
                courseSuggestion: courseSuggestions,
                subTitle: flexCard?.attributes?.field_sac_sub_title?.value ?? "",
                courseCategory: courseCategorys || null,
                detailsPath: flexCard?.attributes?.path?.alias || "",
                enableOverlay: flexCard?.attributes?.course_status == 1 && flexCard?.attributes?.enable_disable_course_completion_overlay == 1,
                overlayLabel: flexCard?.attributes?.overlay_label ?? "",
                enableStatus: flexCard?.attributes?.enable_disable_course_completion_status == 1,
                completionStatusLabel: flexCard?.attributes?.course_status_label ?? "",
                dispStatAbove: false,
                completionStatus: flexCard?.attributes?.course_status ?? null,
              }
            }),
          )
        : []

        return flexibleCards
  }

  const articleCompositionData = async (slide: any) => {
    try {
      const slideData = { data: slide }
      let articleTeaserCompositionData = slide
        ? await getArticleTeaserCompositionData("url", 0, 0, slideData)
        : null
      const result = { data: {} }
      if (articleTeaserCompositionData) {
        result.data = articleTeaserCompositionData
      }
      return result
    } catch (error) {
      console.error("Error in articlecomData:", error)
      throw error
    }
  }

  const menulinkData = async (slide: any) => {
    try {
      const slideData = slide
      let menuLinkIconData = slide ? await getLinkIconData("url", 0, 0, slideData) : null
      const result = { data: {} }
      if (menuLinkIconData) {
        result.data = menuLinkIconData
      }
      return result
    } catch (error) {
      console.error("Error in menulinkdata:", error)
      throw error
    }
  }

  const productTeaserCardsData = async (slide: any) => {
    try {
      const slideData = slide
      let pimProductTeaserData = slide
        ? await getProductTeaserCardData(
            slideData,
            imageCarouselData?.data?.attributes?.enable_paywall_block_level,
          )
        : null

      return pimProductTeaserData
    } catch (error) {
      console.error("Error in pimProductTeaserData:", error)
      throw error
    }
  }

  const getSlidesForProductTeaser = async (slidesData: any) => {
    const object = await getApi(slidesData?.restapi_endpoint, false)
    return object
  }

  const getSlidesInfoForProductTeaser = async (slides: any) => {
    const slidesForProductTeaser = [] as any[]
    slides?.map((item: any) => {
      slidesForProductTeaser?.push(item)
    })
    const productTeaserArray = await productTeaserCardsData(slidesForProductTeaser) ?? []
    let filteredProducts = productTeaserArray.filter(Boolean)
    return filteredProducts && filteredProducts?.map((item: any) => {
      return {
        blockdata: item,
        duration: Number(
          getNestedObject(imageCarouselData, "data.attributes.field_duration") ?? NUMBERS.ZERO,
        ),
        carouselDisplayType,
        carouselBlockType,
        itemPerSlide: Number(
          getNestedObject(imageCarouselData, "data.attributes.field_number_of_items_per_slide_") ??
            NUMBERS.ZERO,
        ),
      }
    })
  }

  const slides =
    carouselDisplayType === CAROUSEL_BLOCK_TYPE.PRODUCT_TEASER_CARDS
      ? imageCarouselData?.data?.relationships?.field_pim_product_teaser?.data ?? []
      : imageCarouselData?.included ?? []
  const getRotatingData = await rotatingHeroBanerData(imageCarouselData)
  const slidesInfo =
    carouselDisplayType === CAROUSEL_BLOCK_TYPE.PRODUCT_TEASER_CARDS
      ? await Promise.all(slides?.map(async (item: any) => await getSlidesForProductTeaser(item)))
      : carouselDisplayType === CAROUSEL_BLOCK_TYPE.SANDOZ_ACADEMY_COURSE_CATALOGUE
      ? await getFlexibleCardsDataForCarousel(imageCarouselData?.data?.relationships?.field_sandoz_academy_course_cata?.links?.related?.href)
      : await Promise.all(
          slides.map(async (slide: any) => {
            const isChildPaywalled = isPaywallProtected(
              getNestedObject(slide, "attributes.enable_paywall_block_level"),
            )?.isPaywallProtectedFlag

            if (isChildPaywalled) {
              return null
            }
            const { ...isImageClickableData } = await rotatingHeroBanerData(slide)

            let blockData

            switch (carouselBlockType) {
              case CAROUSEL_BLOCK_TYPE.HERO_BANNER:
                blockData = await rotatingHeroBanerData(slide)
                break
              case CAROUSEL_BLOCK_TYPE.ARTICLE_TEASER_COMPOSITION_BANNER:
                blockData = await articleCompositionData(slide)
                break
              case CAROUSEL_BLOCK_TYPE.MENU_LINK_WITH_ICON:
                blockData = await menulinkData(slide)
                break
              default:
                throw new Error("Invalid carousel block type")
            }

            return {
              blockdata: blockData,
              eventBannerContent: null,
              uploadType:
                getNestedObject(slide, "attributes.field_hero_banner_upload_type") ?? null,
              referenceField:
                getNestedObject(slide, "attributes.field_reference_field.value") ?? null,
              duration: Number(
                getNestedObject(imageCarouselData, "data.attributes.field_duration") ??
                  NUMBERS.ZERO,
              ),
              isImageClickable: isImageClickableData?.isImageClickable ?? null,
              carouselDisplayType,
              carouselBlockType,
              itemPerSlide: Number(
                getNestedObject(
                  imageCarouselData,
                  "data.attributes.field_number_of_items_per_slide_",
                ) ?? NUMBERS.ZERO,
              ),
            }
          }),
        )

  return {
    duration,
    carouselDisplayType,
    carouselBlockType,
    itemPerSlide,
    slideItemsForTablet:
      carouselDisplayType === CAROUSEL_BLOCK_TYPE.SANDOZ_ACADEMY_COURSE_CATALOGUE
        ? NUMBERS.TWO
        : itemPerSlide,
    slidesInfo:
      CAROUSEL_BLOCK_TYPE.HERO_BANNER === carouselBlockType
        ? getRotatingData
        : carouselDisplayType === CAROUSEL_BLOCK_TYPE.PRODUCT_TEASER_CARDS
        ? await getSlidesInfoForProductTeaser(filterNullValues(slidesInfo))
        : filterNullValues(slidesInfo),
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getFlexibleCarouselData
