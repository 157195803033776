import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Column, Icon, RichText, Row, Seperator } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"
import SearchFilterTable from "@organisms/searchFilterTable"
import { Pagination, TitleDescription } from "@molecules"
import { useRouter } from "next/router"
import { IOption } from "@atoms/dropDown/_dropDown.interface"
import { middlewareGETAPI } from "@utils/baseApi"
import {
  getPoDetailFilterLabelData,
  fetchPoDetailsTableData,
} from "@helpers/dataFunctions/getPurchaseOrderDetails"
import { searchFilterType } from "@organisms/searchFilterTable/_searchFilterTable.interface"
import Loader from "@atoms/loader"
import NUMBERS from "@helpers/constants/numbers"
import EnhancedFilterSearch from "@organisms/enhancedFilterSearch"
import { connect, useDispatch } from "react-redux"
import {
  addUpdatePODetailFilter,
  updateAllPODetailFilters,
  updatePODetailSort,
  updatePODetailPagination,
  updatePODetailSearchText,
} from "store/actions/PoDetailSearchActionCreators"
import { Dispatch } from "redux"
import _ from "lodash"
import { ColumnNameType } from "./_purchase-order-search-filter.interface"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import exportFromJSON from "export-from-json"

const ITEMS_PER_PAGE = NUMBERS.TEN
interface DispatchProps {
  addUpdatePODetailFilter: (data: any) => void
  updatePODetailPagination: (data: number) => void
  updatePODetailSearchText: (data: string) => void
  updateAllPODetailFilters: (data: any, page: number, str: string, sort?: any) => void
  updatePODetailSort: (data: any) => void
}

type StateTypes = {
  poDetailSearchFilterData: {
    poDetailFilters: any
    currentPage: number
    searchText: string
    sort: {
      column: string
      order: string
    }
  }
}

type poDetailsType = {
  blockUrl: string
}

const sortKeyPair: ColumnNameType = {
  ndcNumber: "ndc_number",
  customerMaterialNumber: "customer_material_number",
  itemDescription: "item_description",
  quantityOrdered: "quantity_ordered",
  quantityShipped: "quantity_shipped",
  trackingNumber: "tracking_number",
  packingSlip: "packing_slip",
  invoice: "invoice",
}

type Props = DispatchProps & StateTypes & poDetailsType
const PurchaseOrderDetailFilter = (props: Props) => {
  const { poDetailSearchFilterData, blockUrl } = props
  const { poDetailFilters, currentPage, searchText, sort } = poDetailSearchFilterData
  const [currentSearchText, setCurrentSearchText] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sortValue, setSortValue] = useState<any>({ value: "", order: 0 })
  const [poDetail, setPODetail] = useState<searchFilterType[]>([])
  const [poList, setPoList] = useState<searchFilterType[]>([])
  const [sortedList, setSortedList] = useState<searchFilterType[]>([])
  const [filterDetails, setFilterDetails] = useState<any>({})
  const [resultCount, setResultCount] = useState<number>(0)
  const [isUpdated, setIsUpdated] = useState(false)
  const [localSelectedFilters, setLocalSelectedFilters] = useState<any>(poDetailFilters)
  const [itemsPerPage, setItemsPerPage] = useState<number>(ITEMS_PER_PAGE)

  const disabledColumnSort = ["quantityOrdered", "quantityShipped", "packingSlip", "invoice"]
  const isMobile = useMediaQuery("(max-width: 991px)")
  const router = useRouter()
  const poNumber = router?.query?.po_number

  const filterList: searchFilterType[] = sortValue ? sortedList : poDetail

  const url = `${blockUrl.startsWith("/") ? blockUrl : "/" + blockUrl}/${poNumber}`
  const { data: filterResponse } = useSWRRequest(url, async () => {
    const response = await middlewareGETAPI(url)
    return response.response
  })

  const dispatch: Dispatch<any> = useDispatch()

  const updatePODeailFilterState: any = useCallback(
    (value: any) => {
      props.addUpdatePODetailFilter(value)
    },
    [dispatch],
  )
  const updatePaginationState: any = useCallback(
    (value: number) => props.updatePODetailPagination(value),
    [dispatch],
  )

  const updateSearchTextState: any = useCallback(
    (searchText: string) => props.updatePODetailSearchText(searchText),
    [dispatch],
  )
  const updateAllFiltersState: any = useCallback(
    (value: any, page: number, searchText: string, sort?: any) =>
      props?.updateAllPODetailFilters(value, page, searchText, sort),
    [dispatch],
  )

  const updatePoDetailSortState: any = useCallback(
    (value: any) => props.updatePODetailSort(value),
    [dispatch],
  )

  useEffect(() => {
    if (!filterResponse) {
      return
    }

    const fetchFilterDetails = () => {
      const configData = getPoDetailFilterLabelData(filterResponse)
      const filterLabelsData = {
        searchLabel: configData.searchLabel,
        searchPlaceholder: configData.searchPlaceholder,
        SearchCTAText: configData.searchCTALabel,
        resultsFoundLabel: configData.resultsLabel,
        noResultsFoundLabel: configData.noResultsLabel,
        applyFiltersCTA: configData.applyFiltersCTALabel,
        unSelectItemsLabel: configData.unSelectItemsLabel,
        sortByCTAText: configData.sortByLabel,
        accountInformation: configData.accountInformation,
        billTo: configData.billTo,
        shipTo: configData.shipTo,
        specialInstructions: configData.specialInstructions,
        exportToCTALabel: configData.exportToCTALabel
      }

      const defaultFilter = [] as any

      const createTableColumns = (oldObj: any) => {
        const objKeyMap = {
          ndcNumber: "ndc_number",
          customerMaterialNumber: "customer_material_number",
          itemDescription: "item_description",
          quantityOrdered: "quantity_ordered",
          quantityShipped: "quantity_shipped",
          trackingNumber: "tracking_number",
          packingSlip: "packing_slip",
          invoice: "invoice",
        }
        const newObj = {}
        Object.entries(objKeyMap).forEach(([key, value]) => {
          // @ts-ignore
          newObj[key] = oldObj[value]
        })
        return newObj
      }
      const allColumns = createTableColumns(filterResponse?.items_table_column_labels)
      const displayConfig = {
        tableColumns: Object.values(filterResponse?.items_table_column_labels),
        resultsLabel: configData.resultsLabel,
        noResultsLabel: configData.noResultsLabel,
        noResultsDesc: configData.noResultsDesc,
        sortByLabel: configData.sortByLabel,
        searchUrl: url,
        blockTitle: configData.blockTitle,
      }

      const filterDetailsObj = {
        filtersLabels: filterLabelsData,
        jobLocationData: null,
        displayConfig,
        defaultFilter,
        tableColumns: allColumns,
      }
      const selectedCategories = router?.query
      const tempSele = selectedCategories

      const sortFilter = {
        column: selectedCategories?.sort_field || "",
        order: selectedCategories?.sort_order || "ASC",
      }
      updateAllFiltersState(
        [],
        Number(selectedCategories?.page ?? 0),
        selectedCategories?.search || "",
        sortFilter,
      )
      setFilterDetails(filterDetailsObj)
      setIsUpdated(true)
    }
    fetchFilterDetails()
  }, [filterResponse])

  const handleSearch = (text: string) => {
    updateSearchTextState(text)
  }

  const handleClick = (label: string, value: IOption) => {
    const alteredFilter = poDetailFilters?.map(
      (item: { filterName: string; selectOptions: { options: any[] } }) => {
        if (item.filterName === label) {
          return {
            ...item,
            selectOptions: {
              ...item.selectOptions,
              options: item.selectOptions.options?.map((val) => {
                if (val.id === value?.id) {
                  return {
                    ...val,
                    isSelected: false,
                  }
                }
                return val
              }),
            },
          }
        }
        return item
      },
    )
    updatePODeailFilterState(alteredFilter)
  }
  const handleSortWhole = (key: string) => {
    if (sort.column === "") {
      updatePoDetailSortState({ column: key, order: "ASC" })
    } else if (sort.column && sort.column === key) {
      if (sort.order === "ASC") {
        updatePoDetailSortState({ column: key, order: "DESC" })
      } else if (sort.order === "DESC") {
        updatePoDetailSortState({ column: key, order: "ASC" })
      }
    } else {
      updatePoDetailSortState({ column: key, order: "ASC" })
    }
  }

  const handleSortKey = (key: string) => {
    if (sort.column === "" || sort.column !== key) {
      updatePoDetailSortState({ column: key, order: sort?.order })
    } else {
      updatePoDetailSortState({ column: "", order: sort?.order })
    }
  }

  const handleSortOrder = () => {
    updatePoDetailSortState({ column: sort?.column, order: sort.order === "ASC" ? "DESC" : "ASC" })
  }

  const checkIfValueExists = (val: any, valArr: any) =>
    valArr.findIndex((item: any) => item.id === val.id) > -1
  const handleAdditionalFilters = (label: string, value: any) => {
    if (isMobile) {
      const alteredFilter = localSelectedFilters?.map(
        (item: { filterName: string; selectOptions: { options: any[] } }) => {
          if (item.filterName === label) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val) => ({
                  ...val,
                  isSelected: checkIfValueExists(val, value),
                })),
              },
            }
          }
          return item
        },
      )
      setLocalSelectedFilters(alteredFilter)
    } else {
      const alteredFilter = poDetailFilters?.map(
        (item: { filterName: string; selectOptions: { options: any[] } }) => {
          if (item.filterName === label) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val) => ({
                  ...val,
                  isSelected: checkIfValueExists(val, value),
                })),
              },
            }
          }
          return item
        },
      )
      updatePODeailFilterState(alteredFilter)
    }
  }

  useMemo(() => {
    const searchUrlStr = searchText ? `&search=${encodeURIComponent(searchText)}` : ""

    const sortColumn = sort && sort?.column ? sortKeyPair[sort?.column] : ""
    const sortString = sortColumn
      ? `&sort[sort_by]=${sortColumn}&sort[sort_order]=${sort?.order}`
      : ""

    const getTableData = async () => {
      setIsLoading(true)
      const response = await middlewareGETAPI(
        `restapi/customer-connect/po-details-table?po_number=${poNumber}${searchUrlStr}${sortString}&page=${currentPage}`,
      )
      if (response && response.response) {
        const formatedTableData = fetchPoDetailsTableData(response?.response)
        setPODetail(formatedTableData)
        setSortedList(formatedTableData)
        // @ts-ignore
        const totalResults = response?.response?.meta?.count || 0
        // @ts-ignore
        const itemsToRender = Number(response?.response?.number_of_items_per_page) ?? 0
        setItemsPerPage(itemsToRender)
        setResultCount(totalResults)
      }
      setIsLoading(false)
    }
    if (isUpdated) {
      getTableData().then(() => {
        const { protocol, host, pathname } = window.location
        const sortUrl = sort?.column ? `&sort_field=${sort?.column}&sort_order=${sort?.order}` : ""
        const newurl = `${protocol}//${host}${pathname}` //&page=${currentPage}&search=${searchText || ""}${sortUrl}
        // window.history.replaceState({ path: newurl }, "", newurl)
        setLocalSelectedFilters(poDetailFilters)
      })
    }
  }, [poDetailSearchFilterData, isUpdated])

  const applyAdditionalFilters = () => {
    updatePODeailFilterState(localSelectedFilters)
  }

  const handlePagination = (offSet: number) => {
    updatePaginationState(offSet / itemsPerPage)
  }

  const clearAllFilters = () => {
    const clearedFilters = poDetailFilters?.map((item: any) => ({
      ...item,
      selectOptions: {
        ...item.selectOptions,
        options: item.selectOptions.options?.map((val: any) => {
          // @ts-ignore
          return {
            ...val,
            isSelected: false,
          }
        }),
      },
    }))
    updateAllFiltersState(clearedFilters, 0, searchText || "")
  }

  const formatMatchString = (arrayLength: number) =>
    arrayLength > NUMBERS.ZERO
      ? filterDetails?.displayConfig?.resultsLabel?.replace(
          "@po_details_items_count",
          arrayLength.toString(),
        )
      : filterDetails?.displayConfig?.noResultsLabel?.replace("@search_txt", currentSearchText)

  const resetLocalFilterWithReducer = () => {
    setLocalSelectedFilters(poDetailFilters)
  }

  // export to excel
  const fileName = "purchase order details "
  const exportType = "xls"

  const exportToExcel = async () => {
    const searchUrlStr = searchText ? `&search=${encodeURIComponent(searchText)}` : ""
    const sortColumn = sort && sort?.column ? sortKeyPair[sort?.column] : ""
    const sortString = sortColumn
      ? `&sort[sort_by]=${sortColumn}&sort[sort_order]=${sort?.order}`
      : ""
    const response = await middlewareGETAPI(
      `restapi/customer-connect/po-details-table?po_number=${poNumber}${searchUrlStr}${sortString}`,
    )
    const { data }: any = response?.response

    if (data) {
      exportFromJSON({ data, fileName, exportType, fields: filterResponse?.items_table_column_labels})
    }
  }

  return (
    <>
      {filterDetails?.displayConfig?.blockTitle && (
        <div className="purchase-order-detail-page-title">
          <h2>{filterDetails?.displayConfig?.blockTitle}</h2>
        </div>
      )}
      {filterList?.length > 0 && (
        <div className="export-btn">
          <Column desktop={4} tablet={4} mobile={8}>
            <Button
              tabindex={0}
              onClick={exportToExcel}
              iconPosition="left"
              icon={<Icon iconName="excelToPrintIcon" />}>
              {filterDetails?.filtersLabels?.exportToCTALabel}
            </Button>
          </Column>
        </div>
      )}
      <Row className="purchase-order-details-info-section">
        {filterDetails?.filtersLabels?.accountInformation && (
          <Column desktop={6} tablet={6} mobile={12}>
            <div className="purchase-order-details card-stretch">
              <div className="card">
                <div className="card-body">
                  <div className="purchase-order-details-card-header">
                    <h3>{filterDetails?.filtersLabels?.accountInformation?.label}</h3>
                  </div>
                  <div className="purchase-order-details-card-detail">
                    <p>
                      <span>
                        {filterDetails?.filtersLabels?.accountInformation?.data?.po_number.label}
                      </span>
                      <span>
                        {"#"}
                        {filterDetails?.filtersLabels?.accountInformation?.data?.po_number?.value}
                      </span>
                    </p>
                    <p>
                      <span>
                        {filterDetails?.filtersLabels?.accountInformation?.data?.po_status?.label}
                      </span>
                      <span>
                        {filterDetails?.filtersLabels?.accountInformation?.data?.po_status?.value}
                      </span>
                    </p>
                    <p>
                      <span>
                        {
                          filterDetails?.filtersLabels?.accountInformation?.data?.date_ordered
                            ?.label
                        }
                      </span>
                      <span>
                        {
                          filterDetails?.filtersLabels?.accountInformation?.data?.date_ordered
                            ?.value
                        }
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Column>
        )}

        {filterDetails?.filtersLabels?.billTo && (
          <Column desktop={6} tablet={6} mobile={12}>
            <div className="purchase-order-details card-stretch">
              <div className="card">
                <div className="card-body">
                  <div className="purchase-order-details-card-header">
                    <h3>{filterDetails?.filtersLabels?.billTo?.label}</h3>
                  </div>
                  <div className="purchase-order-details-card-detail">
                    <p>
                      <span>{filterDetails?.filtersLabels?.billTo?.data?.bill_to_name}</span>
                    </p>
                    <p>
                      <span>{filterDetails?.filtersLabels?.billTo?.data?.bill_to_address}</span>
                    </p>
                    <p>
                      <span>{filterDetails?.filtersLabels?.billTo?.data?.bill_to_city}</span>
                    </p>
                    <p>
                      <span>{filterDetails?.filtersLabels?.billTo?.data?.bill_to_zipcode}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Column>
        )}
      </Row>
      <Row className="purchase-order-details-info-section">
        {filterDetails?.filtersLabels?.shipTo && (
          <Column desktop={6} tablet={6} mobile={12}>
            <div className="purchase-order-details card-stretch">
              <div className="card">
                <div className="card-body">
                  <div className="purchase-order-details-card-header">
                    <h3>{filterDetails?.filtersLabels?.shipTo?.label}</h3>
                  </div>
                  <div className="purchase-order-details-card-detail">
                    <p>
                      <span>{filterDetails?.filtersLabels?.shipTo?.data?.ship_to_name}</span>
                    </p>
                    <p>
                      <span>{filterDetails?.filtersLabels?.shipTo?.data?.ship_to_address}</span>
                    </p>
                    <p>
                      <span>{filterDetails?.filtersLabels?.shipTo?.data?.ship_to_city}</span>
                    </p>
                    <p>
                      <span>{filterDetails?.filtersLabels?.shipTo?.data?.ship_to_zipcode}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Column>
        )}
        {filterDetails?.filtersLabels?.specialInstructions && (
          <Column desktop={6} tablet={6} mobile={12}>
            <div className="purchase-order-details card-stretch">
              <div className="card">
                <div className="card-body">
                  <div className="purchase-order-details-card-header">
                    <h3>{filterDetails?.filtersLabels?.specialInstructions?.label}</h3>
                  </div>
                  <RichText content={filterDetails?.filtersLabels?.specialInstructions?.data} />
                </div>
              </div>
            </div>
          </Column>
        )}
      </Row>

      {Object.keys(filterDetails).length ? (
        <div className="careerSearchFilter scroll-top">
          <EnhancedFilterSearch
            filtersOptions={[]}
            filterLabels={filterDetails.filtersLabels}
            handleSearch={handleSearch}
            isCareerSearch={false}
            handleUnselectAll={clearAllFilters}
            handleAdditionalFilters={handleAdditionalFilters}
            selectedFilters={() => {}}
            applyAdditionalFilters={applyAdditionalFilters}
            currentSearchText={currentSearchText}
            handleRemoveItem={handleClick}
            handleSearchText={setCurrentSearchText}
            noOfResults={resultCount}
            handleSortByKey={handleSortKey}
            handleSortByOrder={handleSortOrder}
            sortList={filterDetails.tableColumns}
            selectedSortValue={sort}
            resetLocalFilterWithReducer={resetLocalFilterWithReducer}
            isDisabled={_.isEqual(poDetailFilters, localSelectedFilters)}
            disabledAdvanceSearch={true}
            showResultCount={true}
            displayMobileSort={true}
            disabledColumnSort={disabledColumnSort}
          />
          {!(isMobile && !poList?.length) && <Seperator className="careerSearchFilter-separator" />}
          {isLoading && (
            <div className="load-container">
              <Loader display={true} />
            </div>
          )}
          {!isLoading &&
            (filterList?.length ? (
              <div>
                <SearchFilterTable
                  filterData={filterList}
                  columnsList={filterDetails?.displayConfig.tableColumns}
                  tableColumns={filterDetails.tableColumns}
                  handleSort={handleSortWhole}
                  sortValue={sort}
                  disableSortColumnList={disabledColumnSort}
                />
                <div className="search-filter-pagination">
                  {resultCount > itemsPerPage && (
                    <div className="search-filter-pagination">
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        itemsCount={resultCount}
                        onClick={handlePagination}
                        offSet={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <TitleDescription
                title={formatMatchString(sortedList?.length) ?? ""}
                description={filterDetails.displayConfig?.noResultsDesc?.value ?? ""}
                searchKey=""
                className="careerSearchFilter-no-result"
              />
            ))}
        </div>
      ) : (
        <div className="load-container">
          <Loader display={true} />
        </div>
      )}
    </>
  )
}

const mapDispatch = {
  addUpdatePODetailFilter: (data: any) => addUpdatePODetailFilter(data),
  updatePODetailPagination: (data: number) => updatePODetailPagination(data),
  updatePODetailSearchText: (data: string) => updatePODetailSearchText(data),
  updateAllPODetailFilters: (data: any, page: number, str: string, sort?: any) =>
    updateAllPODetailFilters(data, page, str, sort),
  updatePODetailSort: (data: any) => updatePODetailSort(data),
}

const mapState = (state: any) => ({
  poDetailSearchFilterData: state.poDetailReducer,
})

export default connect(mapState, mapDispatch)(PurchaseOrderDetailFilter)