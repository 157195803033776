import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Column, Icon, Seperator } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"
import SearchFilterTable from "@organisms/searchFilterTable"
import { Pagination, TitleDescription } from "@molecules"
import { useRouter } from "next/router"
import { IOption } from "@atoms/dropDown/_dropDown.interface"
import { middlewareGETAPI } from "@utils/baseApi"
import {
  getUserDetailLabelData,
  fetchUserDetailsTableData,
} from "@helpers/dataFunctions/getUserDetails"
import { searchFilterType } from "@organisms/searchFilterTable/_searchFilterTable.interface"
import Loader from "@atoms/loader"
import NUMBERS from "@helpers/constants/numbers"
import EnhancedFilterSearch from "@organisms/enhancedFilterSearch"
import { connect, useDispatch } from "react-redux"
import {
  addUpdateUserDetailsFilter,
  updateAllUserDetailsFilters,
  updateUserDetailsSort,
  updateUserDetailsPagination,
  updateUserDetailsSearchText,
} from "store/actions/UserDetailsSearchActionCreators"
import {
  addUpdateUserActionFilter,
  updateUserActionPagination,
  updateUserActionSearchText,
  updateAllUserActionFilters,
  updateUserActionSort,
} from "store/actions/UserActionCreators"
import { Dispatch } from "redux"
import _ from "lodash"
import { ColumnNameType } from "./_user-account-search-filter.interface"
import exportFromJSON from "export-from-json"
import ApplicationContext from "@utils/application-context/applicationContext"

const ITEMS_PER_PAGE = NUMBERS.TEN
interface DispatchProps {
  addUpdateUserDetailsFilter: (data: any) => void
  updateUserDetailsPagination: (data: number) => void
  updateUserDetailsSearchText: (data: string) => void
  updateAllUserDetailsFilters: (data: any, page: number, str: string, sort?: any) => void
  updateUserDetailsSort: (data: any) => void

  addUpdateUserActionFilter: (data: any) => void
  updateUserActionPagination: (data: number) => void
  updateUserActionSearchText: (data: string) => void
  updateAllUserActionFilters: (data: any, page: number, str: string, sort?: any) => void
  updateUserActionSort: (data: any) => void
}

type StateTypes = {
  userDetailSearchFilterData: {
    userDetailsFilters: any
    currentPage: number
    searchText: string
    sort: {
      column: string
      order: string
    }
  }

  userActionFilterData: {
    userActionFilters: any
    currentPage: number
    searchText: string
    sort: {
      column: string
      order: string
    }
  }
}

type userListingProps = {
  blockUrl: string
}

type TableType = "userAction" | "userDetail"

const sortKeyPair: ColumnNameType = {
  lastLogin: "last_login",
  name: "name",
  administrator: "administrator",
  company: "company",
  createdBy: "created_by",
  actionItems: "action_items",
}

type Props = DispatchProps & StateTypes & userListingProps
const UserAccountFilter = (props: Props) => {
  const { userDetailSearchFilterData, userActionFilterData, blockUrl } = props
  const { userDetailsFilters, currentPage, searchText, sort } = userDetailSearchFilterData
  const {
    userActionFilters,
    currentPage: uActCurrentPage,
    searchText: uActSearchText,
    sort: uActSort,
  } = userActionFilterData
  const [currentSearchText, setCurrentSearchText] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoadingUAT, setIsLoadingUAT] = useState<boolean>(true)
  const [sortValue, setSortValue] = useState<any>({ value: "", order: 0 })
  const [userList, setUserList] = useState<searchFilterType[]>([])
  const [userActionList, setUserActionList] = useState<searchFilterType[]>([])
  const [ulSortedList, setULSortedList] = useState<searchFilterType[]>([])
  const [ulActionSortedList, setULActionSortedList] = useState<searchFilterType[]>([])
  const [ulFilterDetails, setULFilterDetails] = useState<any>({})
  const [ulActionFilterDetails, setActionULFilterDetails] = useState<any>({})
  const [resultCount, setResultCount] = useState<number>(0)
  const [resultCountUAT, setResultCountUAT] = useState<number>(0)
  const [isUpdated, setIsUpdated] = useState(false)
  const [localSelectedFilters, setLocalSelectedFilters] = useState<any>(userDetailsFilters)
  const [exportTableColumn, setExportTableColumn] = useState({})
  const [localActionSelectedFilters, setLocalActionSelectedFilters] =
    useState<any>(userActionFilters)
  const [itemsPerPage, setItemsPerPage] = useState<number>(ITEMS_PER_PAGE)
  const [itemsPerPageUAT, setItemsPerPageUAT] = useState<number>(ITEMS_PER_PAGE)

  const disabledColumnSort = ["administrator", "actionItems", "createdBy"]
  const isMobile = useMediaQuery("(max-width: 991px)")
  const router = useRouter()

  const userFilterList: searchFilterType[] = sortValue ? ulSortedList : userList

  const userActionFilterList: searchFilterType[] = sortValue ? ulActionSortedList : userActionList

  const { applicationConfiguration } = useContext(ApplicationContext)

  const dispatch: Dispatch<any> = useDispatch()

  const updateUserDetailFilterState: any = useCallback(
    (value: any) => {
      props.addUpdateUserDetailsFilter(value)
    },
    [dispatch],
  )
  const updateUserDetailsPaginationState: any = useCallback(
    (value: number) => props.updateUserDetailsPagination(value),
    [dispatch],
  )

  const updateUserDetailsSearchTextState: any = useCallback(
    (searchText: string) => props.updateUserDetailsSearchText(searchText),
    [dispatch],
  )
  const updateUserDetailsAllFiltersState: any = useCallback(
    (value: any, page: number, searchText: string, sort?: any) =>
      props?.updateAllUserDetailsFilters(value, page, searchText, sort),
    [dispatch],
  )

  const updateUserDetailSortState: any = useCallback(
    (value: any) => props.updateUserDetailsSort(value),
    [dispatch],
  )

  // User action
  const updateUserActionPaginationState: any = useCallback(
    (value: number) => props.updateUserActionPagination(value),
    [dispatch],
  )

  const updateUserActionSearchTextState: any = useCallback(
    (searchText: string) => props.updateUserActionSearchText(searchText),
    [dispatch],
  )
  const updateUserActionsAllFiltersState: any = useCallback(
    (value: any, page: number, searchText: string, sort?: any) =>
      props?.updateAllUserActionFilters(value, page, searchText, sort),
    [dispatch],
  )

  const updateUserActionSortState: any = useCallback(
    (value: any) => props.updateUserActionSort(value),
    [dispatch],
  )

  // User action
  useEffect(() => {
    if (!applicationConfiguration?.siteConfig?.user_listing) {
      return
    }

    const fetchFilterDetails = () => {
      const configData = getUserDetailLabelData(applicationConfiguration.siteConfig)
      setExportTableColumn(configData.ulTableColumns)
      const filterLabelsData = {
        searchLabel: configData.searchLabel,
        searchPlaceholder: configData.searchPlaceholder,
        SearchCTAText: configData.searchCTALabel,
        resultsFoundLabel: configData.resultsLabel,
        noResultsFoundLabel: configData.noResultsLabel,
        sortByCTAText: configData.sortByLabel,
        exportToCTALabel: configData.exportToCTALabel,
        createNewUserLabel: configData.createNewUserLabel,
      }

      const defaultFilter = [] as any

      const createTableColumns = (oldObj: any) => {
        const objKeyMap = {
          lastLogin: "last_login",
          name: "name",
          administrator: "administrator",
          company: "company",
          createdBy: "created_by",
          actionItems: "action_items",
        }
        const newObj = {}
        Object.entries(objKeyMap).forEach(([key, value]) => {
          // @ts-ignore
          newObj[key] = oldObj[value]
        })
        return newObj
      }
      const allColumns = createTableColumns(configData.ulTableColumns)
      const displayConfig = {
        tableColumns: Object.values(configData.ulTableColumns),
        resultsLabel: configData.resultsLabel,
        noResultsLabel: configData.noResultsLabel,
        noResultsDesc: configData.noResultsDesc,
        sortByLabel: configData.sortByLabel,
        userDetailsLabel: configData.userDetailsLabel,
        userActionLabel: configData.userActionLabel,
        userLabel: configData.userLabel,
        searchUrl: `${blockUrl}`,
      }

      const filterDetailsObj = {
        filtersLabels: filterLabelsData,
        displayConfig,
        defaultFilter,
        tableColumns: allColumns,
      }

      const selectedCategories = router?.query
      const tempSele = selectedCategories

      const sortFilter = {
        column: selectedCategories?.sort_field || "",
        order: selectedCategories?.sort_order || "ASC",
      }
      updateUserDetailsAllFiltersState(
        [],
        Number(selectedCategories?.page ?? 0),
        selectedCategories?.search || "",
        sortFilter,
      )
      setULFilterDetails(filterDetailsObj)

      const userActDisplayConfig = {
        ...displayConfig,
        searchUrl: `${blockUrl}`,
      }
      const userActFilterDetailsObj = {
        ...filterDetailsObj,
        displayConfig: userActDisplayConfig,
      }
      updateUserActionsAllFiltersState(
        [],
        Number(selectedCategories?.page ?? 0),
        selectedCategories?.search || "",
        sortFilter,
      )
      setActionULFilterDetails(userActFilterDetailsObj)
      setIsUpdated(true)
    }

    fetchFilterDetails()
  }, [applicationConfiguration.siteConfig])

  const handleSearch = (text: string) => {
    updateUserDetailsSearchTextState(text)
    updateUserActionSearchTextState(text)
  }

  const handleClick = (label: string, value: IOption) => {
    const alteredFilter = userDetailsFilters?.map(
      (item: { filterName: string; selectOptions: { options: any[] } }) => {
        if (item.filterName === label) {
          return {
            ...item,
            selectOptions: {
              ...item.selectOptions,
              options: item.selectOptions.options?.map((val) => {
                if (val.id === value?.id) {
                  return {
                    ...val,
                    isSelected: false,
                  }
                }
                return val
              }),
            },
          }
        }
        return item
      },
    )
    updateUserDetailFilterState(alteredFilter)
  }

  const handleSortWhole = (key: string, tableType: TableType) => {
    if (tableType === "userDetail") {
      if (sort.column === "") {
        updateUserDetailSortState({ column: key, order: "ASC" })
      } else if (sort.column && sort.column === key) {
        if (sort.order === "ASC") {
          updateUserDetailSortState({ column: key, order: "DESC" })
        } else if (sort.order === "DESC") {
          updateUserDetailSortState({ column: key, order: "ASC" })
        }
      } else {
        updateUserDetailSortState({ column: key, order: "ASC" })
      }
    } else {
      if (uActSort.column === "") {
        updateUserActionSortState({ column: key, order: "ASC" })
      } else if (uActSort.column && uActSort.column === key) {
        if (uActSort.order === "ASC") {
          updateUserActionSortState({ column: key, order: "DESC" })
        } else if (uActSort.order === "DESC") {
          updateUserActionSortState({ column: key, order: "ASC" })
        }
      } else {
        updateUserActionSortState({ column: key, order: "ASC" })
      }
    }
  }

  const handleSortKey = (key: string, tableType: TableType) => {
    if (tableType === "userDetail") {
      if (sort.column === "" || sort.column !== key) {
        updateUserDetailSortState({ column: key, order: sort?.order })
      } else {
        updateUserDetailSortState({ column: "", order: sort?.order })
      }
    } else {
      if (uActSort.column === "" || uActSort.column !== key) {
        updateUserActionSortState({ column: key, order: uActSort?.order })
      } else {
        updateUserActionSortState({ column: "", order: uActSort?.order })
      }
    }
  }

  const handleSortOrder = (tableType: TableType) => {
    if (tableType === "userDetail") {
      updateUserDetailSortState({
        column: sort?.column,
        order: sort.order === "ASC" ? "DESC" : "ASC",
      })
    } else {
      updateUserActionSortState({
        column: uActSort?.column,
        order: uActSort.order === "ASC" ? "DESC" : "ASC",
      })
    }
  }

  const checkIfValueExists = (val: any, valArr: any) =>
    valArr.findIndex((item: any) => item.id === val.id) > -1
  const handleAdditionalFilters = (label: string, value: any) => {
    if (isMobile) {
      const alteredFilter = localSelectedFilters?.map(
        (item: { filterName: string; selectOptions: { options: any[] } }) => {
          if (item.filterName === label) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val) => ({
                  ...val,
                  isSelected: checkIfValueExists(val, value),
                })),
              },
            }
          }
          return item
        },
      )
      setLocalSelectedFilters(alteredFilter)
    } else {
      const alteredFilter = userDetailsFilters?.map(
        (item: { filterName: string; selectOptions: { options: any[] } }) => {
          if (item.filterName === label) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val) => ({
                  ...val,
                  isSelected: checkIfValueExists(val, value),
                })),
              },
            }
          }
          return item
        },
      )
      updateUserDetailFilterState(alteredFilter)
    }
  }

  useMemo(() => {
    const searchUrlStr = searchText ? `&search=${encodeURIComponent(searchText)}` : ""
    const sortColumn = sort && sort?.column ? sortKeyPair[sort?.column] : ""
    const sortString = sortColumn
      ? `&sort[sort_by]=${sortColumn}&sort[sort_order]=${sort?.order}`
      : ""

    const getTableData = async () => {
      setIsLoading(true)
      const response = await middlewareGETAPI(
        `${blockUrl}?${searchUrlStr}${sortString}&page=${currentPage}`,
      )
      if (response && response.response) {
        const formatedTableData = fetchUserDetailsTableData(response?.response)
        setUserList(formatedTableData)
        setULSortedList(formatedTableData)
        // @ts-ignore
        const totalResults = response?.response?.meta?.count || 0
        // @ts-ignore
        const itemsToRender = Number(response?.response?.number_of_items_per_page) ?? 0
        setItemsPerPage(itemsToRender)
        setResultCount(totalResults)
      }
      setIsLoading(false)
    }
    if (isUpdated) {
      getTableData().then(() => {
        const { protocol, host, pathname } = window.location
        const sortUrl = sort?.column ? `&sort_field=${sort?.column}&sort_order=${sort?.order}` : ""
        const newurl = `${protocol}//${host}${pathname}` //&page=${currentPage}&search=${searchText || ""}${sortUrl}
        window.history.replaceState({ path: newurl }, "", newurl)
        setLocalSelectedFilters(userDetailsFilters)
      })
    }
  }, [userDetailSearchFilterData, isUpdated])

  // user action
  useMemo(() => {
    const searchUrlStr = uActSearchText ? `&search=${encodeURIComponent(uActSearchText)}` : ""
    const sortColumn = uActSort && uActSort?.column ? sortKeyPair[uActSort?.column] : ""
    const sortString = sortColumn
      ? `&sort[sort_by]=${sortColumn}&sort[sort_order]=${uActSort?.order}`
      : ""

    const getTableData = async () => {
      setIsLoadingUAT(true)
      const response = await middlewareGETAPI(
        `${blockUrl}?${searchUrlStr}${sortString}&page=${uActCurrentPage}`,
      )
      if (response && response.response) {
        const formatedTableData = fetchUserDetailsTableData(response?.response)
        setUserActionList(formatedTableData)
        setULActionSortedList(formatedTableData)
        // @ts-ignore
        const totalResults = response?.response?.meta?.count || 0
        // @ts-ignore
        const itemsToRender = Number(response?.response?.number_of_items_per_page) ?? 0
        setItemsPerPageUAT(itemsToRender)
        setResultCountUAT(totalResults)
      }
      setIsLoadingUAT(false)
    }
    if (isUpdated) {
      getTableData().then(() => {
        const { protocol, host, pathname } = window.location
        const sortUrl = uActSort?.column
          ? `&sort_field=${uActSort?.column}&sort_order=${uActSort?.order}`
          : ""
        const newurl = `${protocol}//${host}${pathname}` //&page=${uActCurrentPage}&search=${uActSearchText || ""}${sortUrl}
        window.history.replaceState({ path: newurl }, "", newurl)
        setLocalActionSelectedFilters(userActionFilters)
      })
    }
  }, [userActionFilterData, isUpdated])

  const applyAdditionalFilters = () => {
    updateUserDetailFilterState(localSelectedFilters)
  }

  const handlePagination = (offSet: number, tableType: TableType) => {
    if (tableType === "userDetail") {
      updateUserDetailsPaginationState(offSet / itemsPerPage)
    } else {
      updateUserActionPaginationState(offSet / itemsPerPage)
    }
  }

  const formatMatchString = (arrayLength: number) =>
    arrayLength > NUMBERS.ZERO
      ? ulFilterDetails?.displayConfig?.resultsLabel?.replace(
          "@items_count",
          arrayLength.toString(),
        )
      : ulFilterDetails?.displayConfig?.noResultsLabel?.replace("@search_txt", currentSearchText)

  const resetLocalFilterWithReducer = () => {
    setLocalSelectedFilters(userDetailsFilters)
  }

  // export to excel
  const fileName = "users list"
  const exportType = "xls"

  const exportToExcel = async () => {
    const searchUrlStr = searchText ? `&search=${encodeURIComponent(searchText)}` : ""
    const sortColumn = sort && sort?.column ? sortKeyPair[sort?.column] : ""
    const sortString = sortColumn
      ? `&sort[sort_by]=${sortColumn}&sort[sort_order]=${sort?.order}`
      : ""
    const response = await middlewareGETAPI(
      `${blockUrl}?${searchUrlStr}${sortString}`,
    )
    const { data }: any = response?.response
    if (data) {
      exportFromJSON({ data, fileName, exportType, fields: exportTableColumn })
    }
  }

  return (
    <>
      {/* @ts-ignore */}
      {ulFilterDetails?.displayConfig?.userDetailsLabel && (
        <TitleDescription title={ulFilterDetails?.displayConfig?.userDetailsLabel} description="" />
      )}
      {/* <div className="user-details-tab">
        {ulFilterDetails?.displayConfig &&
        <Tabs
          tabContentClassName="generic-tab"
          tabContentID="pills-tabContent"
          tabContents={[]}
          tabHeadClassName="tab-nav nav-fill"
          tabHeadItems={[
            {
              active: true,
              children: ulFilterDetails?.displayConfig?.userDetailsLabel,
              itemID: "pills-overview",
              tabDesign: "pills",
            },
          ]}
        />
       }
      </div> */}
      {/* {userFilterList?.length > 0 && (
        <div className="export-btn">
          <Button tabindex={0} iconPosition="left" icon={<Icon iconName="add" />}>
            {ulFilterDetails?.filtersLabels?.createNewUserLabel}
          </Button>
        </div>
      )} */}

      {/* user action table */}
      {Object.keys(ulActionFilterDetails).length ? (
        <div className="careerSearchFilter scroll-top">
          <EnhancedFilterSearch
            filtersOptions={[]}
            filterLabels={ulActionFilterDetails.filtersLabels}
            handleSearch={handleSearch}
            isCareerSearch={false}
            handleUnselectAll={() => {}}
            handleAdditionalFilters={handleAdditionalFilters}
            selectedFilters={() => {}}
            applyAdditionalFilters={applyAdditionalFilters}
            currentSearchText={currentSearchText}
            handleRemoveItem={handleClick}
            handleSearchText={setCurrentSearchText}
            noOfResults={resultCountUAT}
            handleSortByKey={(key: string) => handleSortKey(key, "userAction")}
            handleSortByOrder={() => handleSortOrder("userAction")}
            sortList={ulActionFilterDetails.tableColumns}
            selectedSortValue={sort}
            resetLocalFilterWithReducer={resetLocalFilterWithReducer}
            isDisabled={_.isEqual(userDetailsFilters, localSelectedFilters)}
            showResultCount={true}
            displayMobileSort={true}
            disabledColumnSort={disabledColumnSort}
            disabledAdvanceSearch={true}
          />
          {!(isMobile && !userActionFilterList?.length) && (
            <Seperator className="careerSearchFilter-separator" />
          )}
          {ulFilterDetails?.displayConfig?.userActionLabel && (
            <TitleDescription
              title={ulFilterDetails?.displayConfig?.userActionLabel}
              description=""
            />
          )}

          {isLoadingUAT && (
            <div className="load-container">
              <Loader display={true} />
            </div>
          )}
          {!isLoadingUAT && (
            <>
              <div>
                <SearchFilterTable
                  filterData={userActionFilterList || []}
                  columnsList={ulActionFilterDetails?.displayConfig?.tableColumns}
                  tableColumns={ulActionFilterDetails?.tableColumns}
                  handleSort={(key: string) => handleSortWhole(key, "userAction")}
                  sortValue={uActSort}
                  disableSortColumnList={disabledColumnSort}
                />
                <div className="search-filter-pagination">
                  {resultCountUAT > itemsPerPageUAT && (
                    <div className="search-filter-pagination">
                      <Pagination
                        className="userActionTablePagination"
                        itemsPerPage={itemsPerPageUAT}
                        itemsCount={resultCountUAT}
                        onClick={(offset: number) => handlePagination(offset, "userAction")}
                        offSet={uActCurrentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
              {!userActionFilterList?.length && (
                <TitleDescription
                  title={formatMatchString(ulSortedList?.length) ?? ""}
                  description={ulActionFilterDetails.displayConfig?.noResultsDesc?.value ?? ""}
                  searchKey=""
                  className="careerSearchFilter-no-result h3-title"
                />
              )}
            </>
          )}
        </div>
      ) : (
        <div className="load-container">
          <Loader display={true} />
        </div>
      )}

      {Object.keys(ulFilterDetails).length ? (
        <div className="careerSearchFilter scroll-top">
          <EnhancedFilterSearch
            filtersOptions={[]}
            filterLabels={ulFilterDetails.filtersLabels}
            handleSearch={handleSearch}
            isCareerSearch={false}
            handleUnselectAll={() => {}}
            handleAdditionalFilters={handleAdditionalFilters}
            selectedFilters={() => {}}
            applyAdditionalFilters={applyAdditionalFilters}
            currentSearchText={currentSearchText}
            handleRemoveItem={handleClick}
            handleSearchText={setCurrentSearchText}
            noOfResults={resultCount}
            handleSortByKey={(key: string) => handleSortKey(key, "userDetail")}
            handleSortByOrder={() => handleSortOrder("userDetail")}
            sortList={ulFilterDetails.tableColumns}
            selectedSortValue={sort}
            resetLocalFilterWithReducer={resetLocalFilterWithReducer}
            isDisabled={_.isEqual(userDetailsFilters, localSelectedFilters)}
            disabledAdvanceSearch={true}
            showResultCount={true}
            displayMobileSort={true}
            disabledColumnSort={disabledColumnSort}
            disabledSearch={true}
          />
          <div className="user-details-section">
            {ulFilterDetails?.displayConfig?.userLabel && (
              <TitleDescription title={ulFilterDetails?.displayConfig?.userLabel} description="" />
            )}
          </div>
          {isLoading && (
            <div className="load-container">
              <Loader display={true} />
            </div>
          )}
          <div className="export-btn">
            {userFilterList?.length > 0 && (
              <Column desktop={4} tablet={4} mobile={8}>
                <Button
                  tabindex={0}
                  onClick={exportToExcel}
                  iconPosition="left"
                  icon={<Icon iconName="excelToPrintIcon" />}>
                  {ulFilterDetails?.filtersLabels?.exportToCTALabel}
                </Button>
              </Column>
            )}
          </div>
          {isLoading && (
            <div className="load-container">
              <Loader display={true} />
            </div>
          )}
          {!isLoading && (
            <>
              <div>
                <SearchFilterTable
                  filterData={userFilterList || []}
                  columnsList={ulFilterDetails?.displayConfig?.tableColumns}
                  tableColumns={ulFilterDetails?.tableColumns}
                  handleSort={(key: string) => handleSortWhole(key, "userDetail")}
                  sortValue={sort}
                  disableSortColumnList={disabledColumnSort}
                />
                <div className="search-filter-pagination">
                  {resultCount > itemsPerPage && (
                    <div className="search-filter-pagination">
                      <Pagination
                        className="userDetailsTablePagination"
                        itemsPerPage={itemsPerPage}
                        itemsCount={resultCount}
                        onClick={(offset: number) => handlePagination(offset, "userDetail")}
                        offSet={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
              {!userFilterList?.length && (
                <TitleDescription
                  title={formatMatchString(ulSortedList?.length) ?? ""}
                  description={ulFilterDetails.displayConfig?.noResultsDesc?.value ?? ""}
                  searchKey=""
                  className="careerSearchFilter-no-result h3-title"
                />
              )}
            </>
          )}
        </div>
      ) : (
        <div className="load-container">
          <Loader display={true} />
        </div>
      )}
    </>
  )
}

const mapDispatch = {
  addUpdateUserDetailsFilter: (data: any) => addUpdateUserDetailsFilter(data),
  updateUserDetailsPagination: (data: number) => updateUserDetailsPagination(data),
  updateUserDetailsSearchText: (data: string) => updateUserDetailsSearchText(data),
  updateAllUserDetailsFilters: (data: any, page: number, str: string, sort?: any) =>
    updateAllUserDetailsFilters(data, page, str, sort),
  updateUserDetailsSort: (data: any) => updateUserDetailsSort(data),
  addUpdateUserActionFilter: (data: any) => addUpdateUserActionFilter(data),
  updateUserActionPagination: (data: number) => updateUserActionPagination(data),
  updateUserActionSearchText: (data: string) => updateUserActionSearchText(data),
  updateAllUserActionFilters: (data: any, page: number, str: string, sort?: any) =>
    updateAllUserActionFilters(data, page, str, sort),
  updateUserActionSort: (data: any) => updateUserActionSort(data),
}

const mapState = (state: any) => ({
  userDetailSearchFilterData: state.userDetailSearchReducer,
  userActionFilterData: state.userActionReducer,
})

export default connect(mapState, mapDispatch)(UserAccountFilter)