import { useEffect, useRef, useState } from "react"
import { FlexiTabContent, RichText, TabItem } from "@atoms"
import { IFlexiTabsProps } from "./_flexitabs.interface"
import { useSelector } from "react-redux"
import NUMBERS from "@helpers/constants/numbers"

const FlexiTabs = (props: IFlexiTabsProps) => {
  const tabContents = props.tabContents ? props.tabContents : []
  const activeItem = props.activeTab
  const { tabHeadItems, isToggleTab } = props
  const [selectedItem, setSelectedItem] = useState(activeItem || 0)
  const [dimensions, setDimensions] = useState<number>(0)
  const tabToggleChange: any = useSelector((state: any) => state?.academyCourseReducer?.tabChanged)
  const tabChangeAcadamyCTA: any = useSelector(
    (state: any) => state?.academyCourseReducer?.btnClickTabChanged,
  )
  const elementref = useRef(null) as any
  const handleClick = (index: number) => {
    setSelectedItem(index)
    const tabHeader = document.querySelector(".flexi-tab-heads") as HTMLElement
    const navItem = document.querySelectorAll(".nav-item")[index] as HTMLElement
    tabHeader.scrollLeft = navItem.offsetLeft - tabHeader.clientWidth / 2 + navItem.clientWidth / 2;
    if(isToggleTab) {
      const selectedElement = tabHeader?.childNodes[index] as HTMLElement
      const positionInfo = selectedElement.getBoundingClientRect()
      const gliderHeight = positionInfo.height
      const gliderWidth = positionInfo.width;
      const glider = document.querySelector("#glider") as HTMLElement
      glider.style.width =  `${gliderWidth}px`
      glider.style.height = `${gliderHeight}px`
      glider.style.left = `${selectedElement.offsetLeft}px`
      props?.updateTab({ tabSelected: index === 1 ? true : false })
      props?.changeTab({ tabSelected: index === 1 ? false : true })
      props?.btnClickTabChange({ btnClickTabChange: index === 1 ? false : true })
      if (index === 0) {
        props?.moduleSectionId({ moduleid: null })
      }
    }
  }

  const updateDimenions = () => {
    if(elementref?.current) {
      setDimensions(elementref?.current?.offsetWidth)
    }
  }

  useEffect(() => {
    if (activeItem) {
      setSelectedItem(activeItem)
    }

    if(isToggleTab) {
      const tabHeader = document.querySelector(".flexi-tab-heads") as HTMLElement
      const initialElement = tabHeader?.childNodes[0] as HTMLElement
  
      const positionInfo = initialElement?.getBoundingClientRect()
      const gliderHeight = positionInfo.height
      const gliderWidth = positionInfo.width
      const glider = document.querySelector("#glider") as HTMLElement
      glider.style.width = `${gliderWidth}px`
      glider.style.height = `${gliderHeight}px`
    }
  }, [activeItem])

  useEffect(() => {
    if (isToggleTab && (tabToggleChange || tabChangeAcadamyCTA)) {
      handleClick(NUMBERS.ONE)
      const tabHeader = document.querySelector(".flexi-tab-heads") as HTMLElement
      const headerOffset = 100 // Adjust this value based on your header height
      const elementPosition = tabHeader.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - headerOffset
      window.scrollTo({
        top: offsetPosition,
        //@ts-ignore
         behavior:"instant"
      })
    }
  }, [tabToggleChange, tabChangeAcadamyCTA, isToggleTab])

  useEffect(()=>{
    updateDimenions()
    window.addEventListener("resize", updateDimenions)
    return () => {
      window.removeEventListener("resize", updateDimenions)
    }
  },[])

  useEffect(()=>{
    if(isToggleTab) {
      handleClick(selectedItem)
    }
  },[dimensions])
  
  return (
    <div
      className={
        !isToggleTab ? "tab-container flexi-tab" : "tab-container flexi-tab academy-tab-container"
      } ref={elementref}>
      <ul className="flexi-tab-heads" role="tablist">
        {tabHeadItems?.map((item: any, index: number) => (
          <TabItem
            itemID={item.itemID}
            referrerId={item?.referrerId}
            key={index}
            active={selectedItem === index}
            onClick={() => handleClick(index)}
            tabDesign={item.tabDesign}
            className="no-breaking-words"
            isToggleTab={isToggleTab ?? false}
            greyColor>
            <RichText content={`${item.children}`} />
          </TabItem>
        ))}
        {isToggleTab && (
          <span id="glider" className="tabs-glider">
            {}
          </span>
        )}
      </ul>
      <div className="tab-content generic-tab flexi-tab-body" id="">
        {tabContents?.map((item: any, index: number) => (
          <div
            key={item.itemID}
            className={`tab-pane fade ${selectedItem === index ? "show active" : ""}`}
            role="tabpanel">
            {selectedItem === index ? <FlexiTabContent content={item} /> : <></>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default FlexiTabs
