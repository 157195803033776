import isServer from "@utils/fs/isServer"
import { getAssetPrefixUrl, uniqueLocaleArray } from "@utils/helper"
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios"
import memoryCache, { CacheClass } from "memory-cache"
import { store } from "store/store"
import { resetUser } from "store/actions/ActionCreators"
import { setRefApplicationID, setUserStateGlobally, updateReloadContent } from "store/actions/CommonActionCreators"
import {
  getApplicationPathFromURL,
} from "@utils/session-util/sessionHandler"
import { clearSession } from "store/actions/SessionCreators"
import logMessage from "@utils/fs/logger"
import { LOG_TYPES } from "@helpers/constants/logger"
import NUMBERS from "@helpers/constants/numbers"
import * as Sentry from "@sentry/nextjs"
import { localeArray } from "./config.external"
import axiosRetry from "axios-retry"

const RETRY_COUNT = NUMBERS.THREE

let instance: AxiosInstance
const memCache: CacheClass<string, string> = new memoryCache.Cache()

const { CancelToken } = axios
let source = CancelToken.source()

let isRefreshing = false
let refreshSubscribers: any = []

// Function to refresh the token
export const refreshAccessToken = async (feDomain: string) => {
  try {
    let url = ""
    if (!isServer()) {
      const { location } = window
      const isLocalhost = !!location.hostname.includes("localhost")
  
      // extract CMS path
      const path = feDomain?.includes("/") ? feDomain?.split("/")?.[1] : ""
      const configureUrl = path ? `/api/renew-token/?ccll=${path}` : "/api/renew-token/"
  
      url = getAssetPrefixUrl(configureUrl, isLocalhost)
    } else {
      url = getAssetPrefixUrl("/api/renew-token/", false)
    }
  
    const tkResponse = await fetch(url)
    const { token } = tkResponse ? await tkResponse.json() : {} as any

    if (token) {
      if(!isServer()) {
        sessionStorage.setItem("token", JSON.stringify(token))
        memCache.put(feDomain, token, NUMBERS.REFRESH_INTERVAL)
      }
      else {
        (global.token = token)
      }
    }
    return token
  } catch (error) {
    logMessage(isServer(), LOG_TYPES.ERROR, undefined, undefined, "[Error] Token renewal failed for site " + feDomain, "")
    console.error("[Error] Token refresh failed: ", error)
    throw error
  }
}

const onRefreshed = (accessToken: string) => {
  refreshSubscribers.forEach((callback: any) => callback(accessToken))
  refreshSubscribers = []
}

export const fetcher = (url: string) => getApi(url).then((res) => res)

export const getApiDomainAndLang = () => {
  let domainData = ""
  let lang = ""
  let token = ""
  let feDomain = ""
  let tokenExpiry = ""

  try {
    if (!isServer()) {
      // storage for client invoked api calls
      feDomain = JSON.parse(sessionStorage.getItem("feDomainData") ?? "")
      domainData = JSON.parse(sessionStorage.getItem("domainData") ?? "")
      lang = JSON.parse(sessionStorage.getItem("domainLang") ?? "en")
      token = JSON.parse(sessionStorage.getItem("token") ?? "")
      tokenExpiry = JSON.parse(sessionStorage.getItem("tokenExpiry") ?? "")
    } else {
      feDomain = "" // global.feDomainData
      domainData = "" // global.domainData // server invoked api calls
      lang = "en" // global.domainLang
      token = "" // global.token
    }
  } catch (error) {
    feDomain = ""
    domainData = ""
    lang = "en"
    token = ""
    tokenExpiry = ""
  }

  return {
    feDomain,
    apiDomain: domainData,
    lang: lang || "en",
    token: token ?? "",
    tokenExpiry
  }
}

const initAxiosMiddleware = (serverData?: any) => {
  try {
    const { apiDomain, feDomain } = getApiDomainAndLang()
    const baseDomain = isServer() ? serverData?.cmsUrl : apiDomain
    const feDomainData = isServer() ? serverData?.feUrl : feDomain
    Sentry.captureMessage("axios middleware called with - fe: " + feDomain + " | cms: " + baseDomain + " | isServer: " + isServer())
    let session = ""
    let csrfToken = ""
    let applicationId = ""

    if (!isServer() && store) {
      session = store.getState().appSession?.session
      csrfToken = store.getState().appSession?.csrf
      applicationId = store.getState().appSession?.applicationId
    }

    if (baseDomain) {
      instance = axios.create({
        baseURL: baseDomain ? `https://${baseDomain}` : "",
      })

      axiosRetry(instance, {
        retries: RETRY_COUNT,
        retryCondition: (error) => {
          if (
            [400, 401, 403, 404, 406, 415, 500, 502, 503].includes(error?.response?.status || 0)
          ) {
            return false
          }
          Sentry.captureMessage("API retried ! " + error)
          return true
        },
      })

      const handleRequest = (config: AxiosRequestConfig) => {
        const { token } = getApiDomainAndLang()
        let tokenData = isServer() ? serverData?.token : token
        const previewTokenData =
          typeof window !== "undefined" ? sessionStorage.getItem("flexiblePreviewToken") : ""
        tokenData = previewTokenData ? previewTokenData : tokenData

        // @ts-ignore
        config.headers["Authorization"] = `Bearer ${tokenData}`
        // @ts-ignore
        config.metadata = { startTime: new Date() }
        if (isServer()) {
          // @ts-ignore
          config.headers["Referrer"] = serverData?.feUrl
        }
        
        const isLoginUrl = config.url?.includes("user/login")
        const isLogoutUrl =
          config.url?.includes("user/logout") || config.url?.includes("user-logout")
        if (isServer() && !isLoginUrl) {
          const sessionData = serverData?.session ? JSON.parse(serverData?.session) : undefined
          session = sessionData?.session
          csrfToken = sessionData?.csrfToken
        }

        if (session && csrfToken && !isLoginUrl) {
          // @ts-ignore
          config.headers["X-CSRF-Token"] = csrfToken
          // @ts-ignore
          config.headers["HashedSessionId"] = session
        }

        if (isLogoutUrl) {
          // Cancelling all pending API requests on user logout as the session data is invalidated
          source.cancel("Request cancelled as user session invalidated")
        }

        if (isRefreshing) {
          // Add the request to the refresh subscribers queue
          refreshSubscribers.push(async (accessToken: string) => {
            // @ts-ignore
            config.headers["Authorization"] = `Bearer ${accessToken}`
            return await instance(config)
          })
        }
        Sentry.captureMessage("Info: api: " + baseDomain + "-" + config.url + " fe: " + isServer() ? serverData?.feUrl : feDomain)
        Sentry.captureMessage("isServer: " + isServer() + "Req token:" + tokenData)
        return config
      }

      instance.interceptors.request.use(handleRequest as any)

      const handleError = async (error: AxiosError) => {
        const originalRequest : any = error.config
        let retryCount = (originalRequest?.retryCount || 0) + 1
        if (originalRequest) originalRequest.retryCount = retryCount

        const message = error?.message + " | " + error?.stack
        logMessage(isServer(), LOG_TYPES.ERROR, error.response?.status, error.request?.path, message, baseDomain)
        Sentry.captureException("Exc:SC " + error.response?.status + " api: " + baseDomain + "-" + error.request?.path + " fe: " + isServer() ? serverData?.feUrl : feDomain)

        if (error?.response?.status === 401 && retryCount <= RETRY_COUNT) {
          if (!isRefreshing) {
            isRefreshing = true
            const newAccessToken = await refreshAccessToken(feDomainData)
            isRefreshing = false
            onRefreshed(newAccessToken)
            return await instance(originalRequest as any)
          } else {
            return new Promise((resolve) => {

              refreshSubscribers.push((accessToken: string) => {
                if (originalRequest) {
                  originalRequest.headers.Authorization = `Bearer ${accessToken}`
                  resolve(instance(originalRequest))
                }
              })
            })
          }
        }
        if (error.response?.status === 415) {
          logMessage(isServer(), LOG_TYPES.ERROR, error.response?.status, error.request?.path, "Invalid user session!", baseDomain)

          // clear cookie
          const url = `/api/data-provider/?id=user&appid=${applicationId}`
          const response = await fetch(url, {
            headers: {
              domainPath: getApplicationPathFromURL(),
            },
          })
          const data = await response.json()

          if (data.flush) {
            // clear session & reload
            store.dispatch(resetUser({}))
            store.dispatch(setUserStateGlobally(false, ""))
            store.dispatch(setRefApplicationID(""))
            store.dispatch(clearSession())
            store.dispatch(updateReloadContent(false))
          }

          // localStorage.clear()
          // sessionStorage.clear()
          if (!isServer()) {
            window.location.reload()
          }
        } else {
          return Promise.reject(error.response)
        }
      }

      instance.interceptors.response.use((response: any) => {
        response.config.metadata.endTime = new Date()
        response.duration = response.config.metadata.endTime - response.config.metadata.startTime
        const isLogoutUrl =
          response?.config.url?.includes("user/logout") ||
          response?.config.url?.includes("user-logout")
        if (isLogoutUrl) {
          // Cancelling all pending API requests on user logout as the session data is invalidated
          source.cancel("Request cancelled as user session invalidated")
          source = CancelToken.source()
        }
        logMessage(isServer(), LOG_TYPES.LOG, response?.status, response?.config?.url, undefined, baseDomain, response?.duration, response.headers.get("Cf-Cache-status"))

        return response
      }, handleError)
    }
  } catch (error) {
    const message = error + " (Domain API has not been configured yet!)"
    logMessage(isServer(), LOG_TYPES.ERROR, undefined, undefined, message, serverData?.cmsUrl)
  }
}

const constructUrl = (url: string, serverData?: any) => {
  if (url.startsWith("http")) {
    return url
  }

  const { lang } = getApiDomainAndLang()
  const language = isServer() ? serverData?.language : lang
  let formattedUrl = url.startsWith("/") ? url : `/${url}`

  // remove duplicate parameter from URL
  const urlWithLanguage = formattedUrl.replace("<lang>", language)
  const urlSeperator = urlWithLanguage.split("/")?.filter(Boolean)
  const excludedUrls = [
    "one_trust_policy",
    "views/job_search",
    "views/media_library_search",
    "views/news_search",
  ]

  if (!excludedUrls.some((val: string) => url.includes(val))) {
    const urlPathArray = uniqueLocaleArray(urlSeperator, localeArray)
    formattedUrl = urlPathArray.join("/")
  }
  formattedUrl = formattedUrl.startsWith("/") ? formattedUrl : `/${formattedUrl}`

  return formattedUrl
}

export const getApi = async (url: string, isCache = false, serverData?: any) => {
  const payload = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    cancelToken: source.token,
  }
  
  const regex = /(assets|_next|static|favicon|\.ico)/
  const hasSubstr = regex.test(url)

  const regexForExt = /^.*\.[a-zA-Z0-9]{2,6}(\?.*)?$/g
  const hasAssetExt = regexForExt.test(url)

  if (hasSubstr) {
    return null
  }

  try {
    if (hasAssetExt) {
      throw new Error("InvalidPath")
    }

    url = url.startsWith("http") ? url : await constructUrl(url, serverData)
    const { feDomain } = getApiDomainAndLang()

    // if there are undefined or json,version in URL
    if (
      url.includes("undefined") ||
      url.includes("json,version") ||
      url.includes("/json/version") ||
      url.includes("json?userId") ||
      url.split("/").lastIndexOf("json") !== -1
    ) {
      throw new Error("URLException")
    }

    // console.log("*** URL: ", url)
    initAxiosMiddleware(serverData)

    // const urlWithDoamin = `${instance?.getUri()}${url}`
    // const result = memCache.get(urlWithDoamin)
    // if (result && isCache) {
    //   const response = JSON.parse(result)
    //   return response
    // }
    if (!instance) {
      throw new Error("URLException")
    }
    Sentry.captureMessage("Info: api: " + url + " fe: " + isServer() ? serverData?.feUrl : feDomain)

    const response = await instance
      .get(url, payload)
      .then((res) => {

        const response = res?.data
        
        if (!response || response === null) {
          const message = "response data unavailable " + JSON.stringify(res)
          logMessage(isServer(), LOG_TYPES.ERROR, 200, url, message, instance?.getUri() || serverData?.cmsUrl)
          Sentry.captureException("response data unavailable! " + message)
        }

        for (const key in response) {
          if (key === "error_code" && response[key] === 403) {
            throw new Error("URLUnauthorizedException", { cause: response?.public_teaser_text })
          }
        }

        /** 
         * TODO: Check if it is really needed
         * /
        // const urlWithDoamin = `${instance?.getUri()}${url}`
        // if (isCache && !isServer()) {
        //   /**
        //    * Cache API in mem cache for 30 min
        //    */
        //   memCache.put(urlWithDoamin, JSON.stringify(response), 1800000)
        // }

        return response
      })
      .catch((err) => {
        if (err?.message === "URLUnauthorizedException") {
          throw err
        } else {
          throw err
        }
      })

    return response
  } catch (error: any) {
    const apiDomainUrl = serverData?.cmsUrl || instance?.getUri()
    const message = error?.statusText + error
    logMessage(isServer(), LOG_TYPES.ERROR, error?.status, url, message, apiDomainUrl)
    Sentry.captureException("Catch:"+ error?.status+ url+ message+ apiDomainUrl + isServer() ? serverData?.feUrl : window?.location?.host)
    if (error?.message === "URLException") {
      return null
    }

    if (error?.message === "URLUnauthorizedException") {
      return {
        paywall: true,
        cause: error?.cause,
      }
    }

    if (error?.message === "InvalidPath") {
      return {
        pageData: null,
        is404: true,
      }
    }

    if (error?.status === 404 || error?.status === 403) {
      return {
        pageData: null,
        is404: !!error?.config?.url.includes("/jsonapi/page/"),
      }
    }

    if (error?.message === "InvalidPath") {
      return {
        pageData: null,
        is404: true,
      }
    }

    const errorLog = {
      type: "Server Side exception",
      exception: error?.message,
      cause: `HTTP Status: ${error?.status} - Message: ${error?.cause} - ${url}`,
      time: new Date(),
    }
    logMessage(isServer(), LOG_TYPES.ERROR, error?.status, url, "[Error] " + JSON.stringify(errorLog), apiDomainUrl)
    return null
  }
}

export const postApi = async (url: string, data: object) => {
  try {
    url = url.startsWith("http") ? url : constructUrl(url)

    initAxiosMiddleware()

    if (!instance) {
      throw new Error("URLException")
    }

    const response = await instance.post(url, data, {
      cancelToken: source.token,
    })
    return await response.data
  } catch (error: any) {
    logMessage(isServer(), LOG_TYPES.ERROR, undefined, url, " [Error] " + error?.response?.data?.error )
    return null
  }
}

export const middlewareGETAPI = async (url: string, serverData?: any) => {
  const headers = {
    "Content-Type": "application/json",
  }
  const opts = {
    headers,
    cancelToken: source.token,
  }
  const results = {
    code: 0,
    fail: false,
    response: {},
    error: {},
  }

  const restURL = constructUrl(url, serverData)
  initAxiosMiddleware(serverData)

  if (!instance) {
    throw new Error("URLException")
  }

  return instance
    .get(restURL, opts)
    .then((resp) => {
      results.code = 200
      results.response = resp.data
      return results
    })
    .catch((error) => {
      results.code = error?.response?.status
      results.fail = true
      results.error = error?.response
      return results
    })
}

export const thirdPartyGETAPI = async (url: string) => {
  const headers = {
    "Content-Type": "application/json",
  }
  const opts = {
    headers,
    cancelToken: source.token,
  }
  const startDate : any = new Date()
  const responseApi = await axios
    .get(url, opts)
    .then((res) => {
      const response = res.data
      const message = "3ʳᵈ"
      logMessage(isServer(), LOG_TYPES.LOG, res?.status, url, message, undefined, (Date.now() - startDate))
      return response
    })
    .catch((err) => {
      throw err
    })
  return responseApi
}

export const middlewarePostAPI = async (
  url: string,
  body: object,
  isConstructURLNeeded?: boolean,
  serverData?: any,
) => {
  const headers = {
    "Content-Type": "application/json",
  }
  const isLoginLogoutUrl = url?.includes("user/logout") || url?.includes("user-logout") || url?.includes("user/login")
  const opts = {
    headers,
    cancelToken: isLoginLogoutUrl ? undefined : source.token,
  }
  const results = {
    code: 0,
    fail: false,
    response: {},
    error: {} as any,
  }

  const restURL = isConstructURLNeeded ? `${url}` : constructUrl(url, serverData)
  initAxiosMiddleware(serverData)

  if (!instance) {
    throw new Error("URLException")
  }

  return instance
    .post(restURL, body, opts)
    .then((resp) => {
      results.code = 200
      results.response = resp.data
      return results
    })
    .catch((error) => {
      results.code = error?.status
      results.fail = true
      results.error = error?.data
      logMessage(isServer(), LOG_TYPES.ERROR, error?.status, url, error?.data, instance?.getUri() || serverData?.cmsUrl)
      return results
    })
}
