import { getApi } from "@utils/baseApi"

export const getTaxonomyDetails = (data: string) => {
  const arrData = data.split(",")
  let newArr: any = []
  arrData.forEach((val: string) => {
    newArr.push({
      taxonomyLabel: val,
    })
  })
  return newArr
}
const getCourseBannerData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let rawData = await getApi(url.replace(/\?.*/, ""), false, serverData)

  return {
    customBannerImage: rawData?.[0]?.field_sa_hero_banner_image?.image_style?.src ?? null,
    height: rawData?.[0]?.field_sa_hero_banner_image?.height ?? null,
    width: rawData?.[0]?.field_sa_hero_banner_image?.width ?? null,
    alt: rawData?.[0]?.field_sa_hero_banner_image?.[0]?.alt ?? null,
    iconValue: rawData?.[0]?.icon_type ?? null,
    taxonomies: rawData?.[0]?.field_sandoz_academy_topics
      ? getTaxonomyDetails(rawData?.[0]?.field_sandoz_academy_topics)
      : null,
    customBannerTitle: rawData?.[0]?.title ?? null,
    ctaLabel: rawData?.[0]?.sa_hero_banner_access_course_label ?? null,
    ctaLink: "/",
    credit:
      rawData?.[0]?.field_enable_disable_credits === "On" && rawData?.[0]?.field_credits
        ? [
            {
              taxonomyLabel:
                rawData?.[0]?.field_credits + " " + rawData?.[0]?.sa_hero_banner_credits_label,
            },
          ]
        : null,
  }
}

export default getCourseBannerData
