import { AnchorButton, Column, Row } from "@atoms"
import { ModulesInformation } from "@molecules"
import FlexibleCarousel from "@organisms/flexibleCarousel"
import { IModulesInfoSectionProps } from "./_modulesInfoSection.interface"
import { useSelector } from "react-redux"
import { useEffect, useRef } from "react"
import { useMediaQuery } from "@helpers/hooks"

const ModulesInfoSection = (props: IModulesInfoSectionProps) => {
  const { carouselData, modulesInformation, course_id } = props
  const ref = useRef<any>(modulesInformation?.nid)
  const isMobile = useMediaQuery("(max-width: 767px)")

  const moduleSectionId: any = useSelector((state: any) => state?.academyCourseReducer?.moduleId)

  useEffect(() => {
    if (moduleSectionId === ref?.current) {
      const sectionEle = document?.getElementById(`${modulesInformation?.nid}`)

      if (sectionEle) {
        setTimeout(() => {
          const headerOffset = 50 // Adjust this value based on your header height
          const elementPosition = sectionEle?.getBoundingClientRect()?.top
          const offsetPosition = elementPosition + window.scrollY - headerOffset

          window.scrollTo({
            top: offsetPosition,
          })
        }, 100)
      }
    }
  }, [moduleSectionId])

  return (
    <Row className="modules-info-sec-body" ref={ref} id={modulesInformation?.nid} isTemplateWrapper>
      <>
        {!isMobile ? (
          <Column desktop={8} tablet={6} mobile={12}>
            <FlexibleCarousel
              {...carouselData}
              course_id={course_id}
              module_id={modulesInformation?.nid}
              isActivityCard={true}
            />
          </Column>
        ) : (
          <Column desktop={4} tablet={6} mobile={12}>
            <ModulesInformation
              {...modulesInformation}
              course_id={course_id}
              module_id={modulesInformation?.nid}
              activity_id={carouselData?.slidesInfo?.[0]?.id}
            />
          </Column>
        )}
        {!isMobile ? (
          <Column desktop={4} tablet={6} mobile={12}>
            <ModulesInformation
              {...modulesInformation}
              course_id={course_id}
              module_id={modulesInformation?.nid}
              activity_id={carouselData?.slidesInfo?.[0]?.id}
            />
          </Column>
        ) : (
          <Column desktop={8} tablet={6} mobile={12}>
            <FlexibleCarousel
              {...carouselData}
              course_id={course_id}
              module_id={modulesInformation?.nid}
              isActivityCard={true}
            />
          </Column>
        )}
        {isMobile && (
          <>
            {modulesInformation?.ctaLabel ? (
              <div className="view-cta">
                <AnchorButton
                  href={modulesInformation?.ctaLink ?? "/"}
                  isExternal={false}
                  tabindex={0}
                  isSecondary>
                  {modulesInformation?.ctaLabel}
                </AnchorButton>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    </Row>
  )
}

export default ModulesInfoSection
