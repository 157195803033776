import React, { useEffect, useRef, useState } from "react"
import { IBrandSwitcherProps } from "./_brandSwitcher.interface"
import { Button, Icon } from "@atoms"
import { useRouter } from "next/router"

const BrandSwitcher: React.FC<IBrandSwitcherProps> = ({
  options,
  onSelect,
  className = "",
  selectedItem,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const popoverRef = useRef<HTMLDivElement | null>(null)
  const router = useRouter()

  const handleClickOutside = (event: MouseEvent): void => {
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen])

  useEffect(() => {
    setIsOpen(false)
  }, [router.asPath])

  if (!options?.length) {
    return <></>
  }

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  const handleOptionSelect = (option: any) => {
    setIsOpen(false)
    onSelect(option)
  }

  const filteredOptions = options
    ?.map((option: any) => (option?.id !== selectedItem?.id ? option : null))
    .filter(Boolean)

  return (
    <div className={`dropdown-container ${className}`} ref={popoverRef}>
      <Button
        isSecondary
        className="dropdown-button"
        onClick={toggleDropdown}
        tabindex={0}
        icon={<Icon iconName="Sort" />}
        iconPosition="right">
        {selectedItem?.title || "Select"}
      </Button>
      {isOpen && filteredOptions && filteredOptions?.length > 0 && (
        <ul className="dropdown-menu">
          {filteredOptions.map((option: any) => (
            <li key={option.id} onClick={() => handleOptionSelect(option)}>
              {option.title || ""}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default BrandSwitcher